import { computed } from 'vue';
import { fillLegacyProps } from '../utils/legacyUtil';
export default (treeData, searchValue, { treeNodeFilterProp, filterTreeNode, fieldNames, }) => {
    return computed(() => {
        const { children: fieldChildren } = fieldNames.value;
        if (!searchValue.value || filterTreeNode.value === false) {
            return treeData.value;
        }
        let filterOptionFunc;
        if (typeof filterTreeNode.value === 'function') {
            filterOptionFunc = filterTreeNode.value;
        }
        else {
            const upperStr = searchValue.value.toUpperCase();
            filterOptionFunc = (_, dataNode) => {
                const value = dataNode[treeNodeFilterProp.value];
                return String(value).toUpperCase().includes(upperStr);
            };
        }
        function dig(list, keepAll = false) {
            return list
                .map(dataNode => {
                const children = dataNode[fieldChildren];
                const match = keepAll || filterOptionFunc(searchValue.value, fillLegacyProps(dataNode));
                const childList = dig(children || [], match);
                if (match || childList.length) {
                    return Object.assign(Object.assign({}, dataNode), { [fieldChildren]: childList });
                }
                return null;
            })
                .filter(node => node);
        }
        return dig(treeData.value);
    });
};
