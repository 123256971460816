import { reactive, provide, defineComponent, watch, watchEffect } from 'vue';
import PropTypes from '../_util/vue-types';
import defaultRenderEmpty from './renderEmpty';
import LocaleProvider, { ANT_MARK } from '../locale-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import message from '../message';
import notification from '../notification';
export const configConsumerProps = [
    'getTargetContainer',
    'getPopupContainer',
    'rootPrefixCls',
    'getPrefixCls',
    'renderEmpty',
    'csp',
    'autoInsertSpaceInButton',
    'locale',
    'pageHeader',
];
export const defaultPrefixCls = 'ant';
function getGlobalPrefixCls() {
    return globalConfigForApi.prefixCls || defaultPrefixCls;
}
const globalConfigByCom = reactive({});
const globalConfigBySet = reactive({}); // 权重最大
export const globalConfigForApi = reactive({});
watchEffect(() => {
    Object.assign(globalConfigForApi, globalConfigByCom, globalConfigBySet);
    globalConfigForApi.prefixCls = getGlobalPrefixCls();
    globalConfigForApi.getPrefixCls = (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return suffixCls
            ? `${globalConfigForApi.prefixCls}-${suffixCls}`
            : globalConfigForApi.prefixCls;
    };
    globalConfigForApi.getRootPrefixCls = (rootPrefixCls, customizePrefixCls) => {
        // Customize rootPrefixCls is first priority
        if (rootPrefixCls) {
            return rootPrefixCls;
        }
        // If Global prefixCls provided, use this
        if (globalConfigForApi.prefixCls) {
            return globalConfigForApi.prefixCls;
        }
        // [Legacy] If customize prefixCls provided, we cut it to get the prefixCls
        if (customizePrefixCls && customizePrefixCls.includes('-')) {
            return customizePrefixCls.replace(/^(.*)-[^-]*$/, '$1');
        }
        // Fallback to default prefixCls
        return getGlobalPrefixCls();
    };
});
let stopWatchEffect;
const setGlobalConfig = (params) => {
    if (stopWatchEffect) {
        stopWatchEffect();
    }
    stopWatchEffect = watchEffect(() => {
        Object.assign(globalConfigBySet, reactive(params));
    });
};
export const globalConfig = () => ({
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return suffixCls ? `${getGlobalPrefixCls()}-${suffixCls}` : getGlobalPrefixCls();
    },
    getRootPrefixCls: (rootPrefixCls, customizePrefixCls) => {
        // Customize rootPrefixCls is first priority
        if (rootPrefixCls) {
            return rootPrefixCls;
        }
        // If Global prefixCls provided, use this
        if (globalConfigForApi.prefixCls) {
            return globalConfigForApi.prefixCls;
        }
        // [Legacy] If customize prefixCls provided, we cut it to get the prefixCls
        if (customizePrefixCls && customizePrefixCls.includes('-')) {
            return customizePrefixCls.replace(/^(.*)-[^-]*$/, '$1');
        }
        // Fallback to default prefixCls
        return getGlobalPrefixCls();
    },
});
export const configProviderProps = {
    getTargetContainer: {
        type: Function,
    },
    getPopupContainer: {
        type: Function,
    },
    prefixCls: String,
    getPrefixCls: {
        type: Function,
    },
    renderEmpty: {
        type: Function,
    },
    transformCellText: {
        type: Function,
    },
    csp: {
        type: Object,
        default: undefined,
    },
    input: {
        type: Object,
    },
    autoInsertSpaceInButton: PropTypes.looseBool,
    locale: {
        type: Object,
    },
    pageHeader: {
        type: Object,
    },
    componentSize: {
        type: String,
    },
    direction: {
        type: String,
    },
    space: {
        type: Object,
    },
    virtual: PropTypes.looseBool,
    dropdownMatchSelectWidth: { type: [Number, Boolean], default: true },
    form: {
        type: Object,
    },
    // internal use
    notUpdateGlobalConfig: Boolean,
};
const ConfigProvider = defineComponent({
    name: 'AConfigProvider',
    inheritAttrs: false,
    props: configProviderProps,
    setup(props, { slots }) {
        const getPrefixCls = (suffixCls, customizePrefixCls) => {
            const { prefixCls = 'ant' } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            return suffixCls ? `${prefixCls}-${suffixCls}` : prefixCls;
        };
        const renderEmptyComponent = (name) => {
            const renderEmpty = (props.renderEmpty ||
                slots.renderEmpty ||
                defaultRenderEmpty);
            return renderEmpty(name);
        };
        const getPrefixClsWrapper = (suffixCls, customizePrefixCls) => {
            const { prefixCls } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            const mergedPrefixCls = prefixCls || getPrefixCls('');
            return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
        };
        const configProvider = reactive(Object.assign(Object.assign({}, props), { getPrefixCls: getPrefixClsWrapper, renderEmpty: renderEmptyComponent }));
        Object.keys(props).forEach(key => {
            watch(() => props[key], () => {
                configProvider[key] = props[key];
            });
        });
        if (!props.notUpdateGlobalConfig) {
            Object.assign(globalConfigByCom, configProvider);
            watch(configProvider, () => {
                Object.assign(globalConfigByCom, configProvider);
            });
        }
        provide('configProvider', configProvider);
        const renderProvider = (legacyLocale) => {
            var _a;
            return (<LocaleProvider locale={props.locale || legacyLocale} ANT_MARK__={ANT_MARK}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </LocaleProvider>);
        };
        watchEffect(() => {
            if (props.direction) {
                message.config({
                    rtl: props.direction === 'rtl',
                });
                notification.config({
                    rtl: props.direction === 'rtl',
                });
            }
        });
        return () => (<LocaleReceiver children={(_, __, legacyLocale) => renderProvider(legacyLocale)}/>);
    },
});
export const defaultConfigProvider = reactive({
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return suffixCls ? `ant-${suffixCls}` : 'ant';
    },
    renderEmpty: defaultRenderEmpty,
    direction: 'ltr',
});
ConfigProvider.config = setGlobalConfig;
ConfigProvider.install = function (app) {
    app.component(ConfigProvider.name, ConfigProvider);
};
export default ConfigProvider;
