import { conductCheck } from '../../vc-tree/utils/conductUtil';
import { shallowRef, watchEffect } from 'vue';
export default (rawLabeledValues, rawHalfCheckedValues, treeConduction, keyEntities) => {
    const newRawCheckedValues = shallowRef([]);
    const newRawHalfCheckedValues = shallowRef([]);
    watchEffect(() => {
        let checkedKeys = rawLabeledValues.value.map(({ value }) => value);
        let halfCheckedKeys = rawHalfCheckedValues.value.map(({ value }) => value);
        const missingValues = checkedKeys.filter(key => !keyEntities.value[key]);
        if (treeConduction.value) {
            ({ checkedKeys, halfCheckedKeys } = conductCheck(checkedKeys, true, keyEntities.value));
        }
        newRawCheckedValues.value = Array.from(new Set([...missingValues, ...checkedKeys]));
        newRawHalfCheckedValues.value = halfCheckedKeys;
    });
    return [newRawCheckedValues, newRawHalfCheckedValues];
};
