import { getCurrentInstance, onBeforeUnmount, onMounted, watch, ref, defineComponent, nextTick, withDirectives, } from 'vue';
import antInputDirective from '../_util/antInputDirective';
import classNames from '../_util/classNames';
import inputProps from './inputProps';
import ClearableLabeledInput from './ClearableLabeledInput';
import { useInjectFormItemContext } from '../form/FormItemContext';
import omit from '../_util/omit';
import useConfigInject from '../_util/hooks/useConfigInject';
export function fixControlledValue(value) {
    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    return value;
}
export function resolveOnChange(target, e, onChange, targetValue) {
    if (!onChange) {
        return;
    }
    const event = e;
    if (e.type === 'click') {
        Object.defineProperty(event, 'target', {
            writable: true,
        });
        Object.defineProperty(event, 'currentTarget', {
            writable: true,
        });
        // click clear icon
        //event = Object.create(e);
        const currentTarget = target.cloneNode(true);
        event.target = currentTarget;
        event.currentTarget = currentTarget;
        // change target ref value cause e.target.value should be '' when clear input
        currentTarget.value = '';
        onChange(event);
        return;
    }
    // Trigger by composition event, this means we need force change the input value
    if (targetValue !== undefined) {
        Object.defineProperty(event, 'target', {
            writable: true,
        });
        Object.defineProperty(event, 'currentTarget', {
            writable: true,
        });
        event.target = target;
        event.currentTarget = target;
        target.value = targetValue;
        onChange(event);
        return;
    }
    onChange(event);
}
export function getInputClassName(prefixCls, bordered, size, disabled, direction) {
    return classNames(prefixCls, {
        [`${prefixCls}-sm`]: size === 'small',
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-disabled`]: disabled,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
    });
}
export function triggerFocus(element, option) {
    if (!element)
        return;
    element.focus(option);
    // Selection content
    const { cursor } = option || {};
    if (cursor) {
        const len = element.value.length;
        switch (cursor) {
            case 'start':
                element.setSelectionRange(0, 0);
                break;
            case 'end':
                element.setSelectionRange(len, len);
                break;
            default:
                element.setSelectionRange(0, len);
        }
    }
}
export default defineComponent({
    name: 'AInput',
    inheritAttrs: false,
    props: Object.assign({}, inputProps),
    setup(props, { slots, attrs, expose, emit }) {
        const inputRef = ref();
        const clearableInputRef = ref();
        let removePasswordTimeout;
        const formItemContext = useInjectFormItemContext();
        const { direction, prefixCls, size, autocomplete } = useConfigInject('input', props);
        const stateValue = ref(props.value === undefined ? props.defaultValue : props.value);
        const focused = ref(false);
        watch(() => props.value, () => {
            stateValue.value = props.value;
        });
        watch(() => props.disabled, () => {
            if (props.value !== undefined) {
                stateValue.value = props.value;
            }
        });
        const clearPasswordValueAttribute = () => {
            // https://github.com/ant-design/ant-design/issues/20541
            removePasswordTimeout = setTimeout(() => {
                var _a;
                if (((_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.getAttribute('type')) === 'password' &&
                    inputRef.value.hasAttribute('value')) {
                    inputRef.value.removeAttribute('value');
                }
            });
        };
        const focus = (option) => {
            triggerFocus(inputRef.value, option);
        };
        const blur = () => {
            var _a;
            (_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.blur();
        };
        const setSelectionRange = (start, end, direction) => {
            var _a;
            (_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.setSelectionRange(start, end, direction);
        };
        const select = () => {
            var _a;
            (_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.select();
        };
        expose({
            focus,
            blur,
            input: inputRef,
            stateValue,
            setSelectionRange,
            select,
        });
        const onFocus = e => {
            const { onFocus } = props;
            focused.value = true;
            onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
            nextTick(() => {
                clearPasswordValueAttribute();
            });
        };
        const onBlur = e => {
            const { onBlur } = props;
            focused.value = false;
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
            formItemContext.onFieldBlur();
            nextTick(() => {
                clearPasswordValueAttribute();
            });
        };
        const triggerChange = (e) => {
            emit('update:value', e.target.value);
            emit('change', e);
            emit('input', e);
            formItemContext.onFieldChange();
        };
        const instance = getCurrentInstance();
        const setValue = (value, callback) => {
            if (stateValue.value === value) {
                return;
            }
            if (props.value === undefined) {
                stateValue.value = value;
            }
            else {
                nextTick(() => {
                    if (inputRef.value.value !== stateValue.value) {
                        instance.update();
                    }
                });
            }
            nextTick(() => {
                callback && callback();
            });
        };
        const handleReset = (e) => {
            resolveOnChange(inputRef.value, e, triggerChange);
            setValue('', () => {
                focus();
            });
        };
        const handleChange = (e) => {
            const { value, composing } = e.target;
            // https://github.com/vueComponent/ant-design-vue/issues/2203
            if (((e.isComposing || composing) && props.lazy) || stateValue.value === value)
                return;
            const newVal = e.target.value;
            resolveOnChange(inputRef.value, e, triggerChange);
            setValue(newVal, () => {
                clearPasswordValueAttribute();
            });
        };
        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                emit('pressEnter', e);
            }
            emit('keydown', e);
        };
        onMounted(() => {
            if (process.env.NODE_ENV === 'test') {
                if (props.autofocus) {
                    focus();
                }
            }
            clearPasswordValueAttribute();
        });
        onBeforeUnmount(() => {
            clearTimeout(removePasswordTimeout);
        });
        const renderInput = () => {
            var _a;
            const { addonBefore = slots.addonBefore, addonAfter = slots.addonAfter, disabled, bordered = true, valueModifiers = {}, htmlSize, } = props;
            const otherProps = omit(props, [
                'prefixCls',
                'onPressEnter',
                'addonBefore',
                'addonAfter',
                'prefix',
                'suffix',
                'allowClear',
                // Input elements must be either controlled or uncontrolled,
                // specify either the value prop, or the defaultValue prop, but not both.
                'defaultValue',
                'size',
                'inputType',
                'bordered',
                'htmlSize',
                'lazy',
            ]);
            const inputProps = Object.assign(Object.assign(Object.assign({}, otherProps), attrs), { autocomplete: autocomplete.value, onChange: handleChange, onInput: handleChange, onFocus,
                onBlur, onKeydown: handleKeyDown, class: classNames(getInputClassName(prefixCls.value, bordered, size.value, disabled, direction.value), {
                    [attrs.class]: attrs.class && !addonBefore && !addonAfter,
                }), ref: inputRef, key: 'ant-input', size: htmlSize, id: (_a = otherProps.id) !== null && _a !== void 0 ? _a : formItemContext.id.value });
            if (valueModifiers.lazy) {
                delete inputProps.onInput;
            }
            if (!inputProps.autofocus) {
                delete inputProps.autofocus;
            }
            const inputNode = <input {...inputProps}/>;
            return withDirectives(inputNode, [[antInputDirective]]);
        };
        return () => {
            const inputProps = Object.assign(Object.assign(Object.assign({}, attrs), props), { prefixCls: prefixCls.value, inputType: 'input', value: fixControlledValue(stateValue.value), handleReset, focused: focused.value && !props.disabled });
            return (<ClearableLabeledInput {...omit(inputProps, ['element', 'valueModifiers'])} ref={clearableInputRef} v-slots={Object.assign(Object.assign({}, slots), { element: renderInput })}/>);
        };
    },
});
