import { computed } from 'vue';
import { convertChildrenToData } from '../utils/legacyUtil';
function parseSimpleTreeData(treeData, { id, pId, rootPId }) {
    const keyNodes = {};
    const rootNodeList = [];
    // Fill in the map
    const nodeList = treeData.map(node => {
        const clone = Object.assign({}, node);
        const key = clone[id];
        keyNodes[key] = clone;
        clone.key = clone.key || key;
        return clone;
    });
    // Connect tree
    nodeList.forEach(node => {
        const parentKey = node[pId];
        const parent = keyNodes[parentKey];
        // Fill parent
        if (parent) {
            parent.children = parent.children || [];
            parent.children.push(node);
        }
        // Fill root tree node
        if (parentKey === rootPId || (!parent && rootPId === null)) {
            rootNodeList.push(node);
        }
    });
    return rootNodeList;
}
/**
 * Convert `treeData` or `children` into formatted `treeData`.
 * Will not re-calculate if `treeData` or `children` not change.
 */
export default function useTreeData(treeData, children, simpleMode) {
    return computed(() => {
        if (treeData.value) {
            return simpleMode.value
                ? parseSimpleTreeData(treeData.value, Object.assign({ id: 'id', pId: 'pId', rootPId: null }, (simpleMode.value !== true ? simpleMode.value : {})))
                : treeData.value;
        }
        return convertChildrenToData(children.value);
    });
}
