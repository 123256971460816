import { onMounted, ref, defineComponent, onBeforeUnmount } from 'vue';
import Button from '../button';
import { convertLegacyProps } from '../button/buttonTypes';
const actionButtonProps = {
    type: {
        type: String,
    },
    actionFn: Function,
    close: Function,
    autofocus: Boolean,
    prefixCls: String,
    buttonProps: Object,
    emitEvent: Boolean,
    quitOnNullishReturnValue: Boolean,
};
function isThenable(thing) {
    return !!(thing && !!thing.then);
}
export default defineComponent({
    name: 'ActionButton',
    props: actionButtonProps,
    setup(props, { slots }) {
        const clickedRef = ref(false);
        const buttonRef = ref();
        const loading = ref(false);
        let timeoutId;
        onMounted(() => {
            if (props.autofocus) {
                timeoutId = setTimeout(() => { var _a; return (_a = buttonRef.value.$el) === null || _a === void 0 ? void 0 : _a.focus(); });
            }
        });
        onBeforeUnmount(() => {
            clearTimeout(timeoutId);
        });
        const handlePromiseOnOk = (returnValueOfOnOk) => {
            const { close } = props;
            if (!isThenable(returnValueOfOnOk)) {
                return;
            }
            loading.value = true;
            returnValueOfOnOk.then((...args) => {
                loading.value = false;
                close(...args);
                clickedRef.value = false;
            }, (e) => {
                // Emit error when catch promise reject
                // eslint-disable-next-line no-console
                console.error(e);
                // See: https://github.com/ant-design/ant-design/issues/6183
                loading.value = false;
                clickedRef.value = false;
            });
        };
        const onClick = (e) => {
            const { actionFn, close = () => { } } = props;
            if (clickedRef.value) {
                return;
            }
            clickedRef.value = true;
            if (!actionFn) {
                close();
                return;
            }
            let returnValueOfOnOk;
            if (props.emitEvent) {
                returnValueOfOnOk = actionFn(e);
                if (props.quitOnNullishReturnValue && !isThenable(returnValueOfOnOk)) {
                    clickedRef.value = false;
                    close(e);
                    return;
                }
            }
            else if (actionFn.length) {
                returnValueOfOnOk = actionFn(close);
                // https://github.com/ant-design/ant-design/issues/23358
                clickedRef.value = false;
            }
            else {
                returnValueOfOnOk = actionFn();
                if (!returnValueOfOnOk) {
                    close();
                    return;
                }
            }
            handlePromiseOnOk(returnValueOfOnOk);
        };
        return () => {
            const { type, prefixCls, buttonProps } = props;
            return (<Button {...convertLegacyProps(type)} onClick={onClick} loading={loading.value} prefixCls={prefixCls} {...buttonProps} ref={buttonRef} v-slots={slots}></Button>);
        };
    },
});
