import PropTypes from '../_util/vue-types';
export const abstractCheckboxGroupProps = () => {
    return {
        name: String,
        prefixCls: String,
        options: {
            type: Array,
            default: () => [],
        },
        disabled: Boolean,
        id: String,
    };
};
export const checkboxGroupProps = () => {
    return Object.assign(Object.assign({}, abstractCheckboxGroupProps()), { defaultValue: { type: Array }, value: { type: Array }, onChange: { type: Function }, 'onUpdate:value': {
            type: Function,
        } });
};
export const abstractCheckboxProps = () => {
    return {
        prefixCls: String,
        defaultChecked: { type: Boolean, default: undefined },
        checked: { type: Boolean, default: undefined },
        disabled: { type: Boolean, default: undefined },
        isGroup: { type: Boolean, default: undefined },
        value: PropTypes.any,
        name: String,
        id: String,
        indeterminate: { type: Boolean, default: undefined },
        type: { type: String, default: 'checkbox' },
        autofocus: { type: Boolean, default: undefined },
        onChange: PropTypes.func,
        'onUpdate:checked': PropTypes.func,
        onClick: PropTypes.func,
        skipGroup: { type: Boolean, default: false },
    };
};
export const checkboxProps = () => {
    return Object.assign(Object.assign({}, abstractCheckboxProps()), { indeterminate: { type: Boolean, default: false } });
};
export const CheckboxGroupContextKey = Symbol('CheckboxGroupContext');
