var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import TransBtn from './TransBtn';
import KeyCode from '../_util/KeyCode';
import classNames from '../_util/classNames';
import pickAttrs from '../_util/pickAttrs';
import { isValidElement } from '../_util/props-util';
import createRef from '../_util/createRef';
import { computed, defineComponent, nextTick, reactive, watch } from 'vue';
import List from '../vc-virtual-list';
import useMemo from '../_util/hooks/useMemo';
import { isPlatformMac } from './utils/platformUtil';
import omit from '../_util/omit';
import useBaseProps from './hooks/useBaseProps';
import useSelectProps from './SelectContext';
/**
 * Using virtual list of option display.
 * Will fallback to dom if use customize render.
 */
const OptionList = defineComponent({
    name: 'OptionList',
    inheritAttrs: false,
    slots: ['option'],
    setup(_, { expose, slots }) {
        const baseProps = useBaseProps();
        const props = useSelectProps();
        const itemPrefixCls = computed(() => `${baseProps.prefixCls}-item`);
        const memoFlattenOptions = useMemo(() => props.flattenOptions, [() => baseProps.open, () => props.flattenOptions], next => next[0]);
        // =========================== List ===========================
        const listRef = createRef();
        const onListMouseDown = event => {
            event.preventDefault();
        };
        const scrollIntoView = (index) => {
            if (listRef.current) {
                listRef.current.scrollTo({ index });
            }
        };
        // ========================== Active ==========================
        const getEnabledActiveIndex = (index, offset = 1) => {
            const len = memoFlattenOptions.value.length;
            for (let i = 0; i < len; i += 1) {
                const current = (index + i * offset + len) % len;
                const { group, data } = memoFlattenOptions.value[current];
                if (!group && !data.disabled) {
                    return current;
                }
            }
            return -1;
        };
        const state = reactive({
            activeIndex: getEnabledActiveIndex(0),
        });
        const setActive = (index, fromKeyboard = false) => {
            state.activeIndex = index;
            const info = { source: fromKeyboard ? 'keyboard' : 'mouse' };
            // Trigger active event
            const flattenItem = memoFlattenOptions.value[index];
            if (!flattenItem) {
                props.onActiveValue(null, -1, info);
                return;
            }
            props.onActiveValue(flattenItem.data.value, index, info);
        };
        // Auto active first item when list length or searchValue changed
        watch([() => memoFlattenOptions.value.length, () => baseProps.searchValue], () => {
            setActive(props.defaultActiveFirstOption !== false ? getEnabledActiveIndex(0) : -1);
        }, { immediate: true });
        // Auto scroll to item position in single mode
        watch([() => baseProps.open, () => baseProps.searchValue], () => {
            if (!baseProps.multiple && baseProps.open && props.rawValues.size === 1) {
                const value = Array.from(props.rawValues)[0];
                const index = memoFlattenOptions.value.findIndex(({ data }) => data.value === value);
                if (index !== -1) {
                    setActive(index);
                    nextTick(() => {
                        scrollIntoView(index);
                    });
                }
            }
            // Force trigger scrollbar visible when open
            if (baseProps.open) {
                nextTick(() => {
                    var _a;
                    (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(undefined);
                });
            }
        }, { immediate: true, flush: 'post' });
        // ========================== Values ==========================
        const onSelectValue = (value) => {
            if (value !== undefined) {
                props.onSelect(value, { selected: !props.rawValues.has(value) });
            }
            // Single mode should always close by select
            if (!baseProps.multiple) {
                baseProps.toggleOpen(false);
            }
        };
        const getLabel = (item) => item.label;
        function renderItem(index) {
            const item = memoFlattenOptions.value[index];
            if (!item)
                return null;
            const itemData = item.data || {};
            const { value } = itemData;
            const { group } = item;
            const attrs = pickAttrs(itemData, true);
            const mergedLabel = getLabel(item);
            return item ? (<div aria-label={typeof mergedLabel === 'string' && !group ? mergedLabel : null} {...attrs} key={index} role={group ? 'presentation' : 'option'} id={`${baseProps.id}_list_${index}`} aria-selected={props.rawValues.has(value)}>
          {value}
        </div>) : null;
        }
        const onKeydown = (event) => {
            const { which, ctrlKey } = event;
            switch (which) {
                // >>> Arrow keys & ctrl + n/p on Mac
                case KeyCode.N:
                case KeyCode.P:
                case KeyCode.UP:
                case KeyCode.DOWN: {
                    let offset = 0;
                    if (which === KeyCode.UP) {
                        offset = -1;
                    }
                    else if (which === KeyCode.DOWN) {
                        offset = 1;
                    }
                    else if (isPlatformMac() && ctrlKey) {
                        if (which === KeyCode.N) {
                            offset = 1;
                        }
                        else if (which === KeyCode.P) {
                            offset = -1;
                        }
                    }
                    if (offset !== 0) {
                        const nextActiveIndex = getEnabledActiveIndex(state.activeIndex + offset, offset);
                        scrollIntoView(nextActiveIndex);
                        setActive(nextActiveIndex, true);
                    }
                    break;
                }
                // >>> Select
                case KeyCode.ENTER: {
                    // value
                    const item = memoFlattenOptions.value[state.activeIndex];
                    if (item && !item.data.disabled) {
                        onSelectValue(item.data.value);
                    }
                    else {
                        onSelectValue(undefined);
                    }
                    if (baseProps.open) {
                        event.preventDefault();
                    }
                    break;
                }
                // >>> Close
                case KeyCode.ESC: {
                    baseProps.toggleOpen(false);
                    if (baseProps.open) {
                        event.stopPropagation();
                    }
                }
            }
        };
        const onKeyup = () => { };
        const scrollTo = (index) => {
            scrollIntoView(index);
        };
        expose({
            onKeydown,
            onKeyup,
            scrollTo,
        });
        return () => {
            // const {
            //   renderItem,
            //   listRef,
            //   onListMouseDown,
            //   itemPrefixCls,
            //   setActive,
            //   onSelectValue,
            //   memoFlattenOptions,
            //   $slots,
            // } = this as any;
            const { id, notFoundContent, onPopupScroll } = baseProps;
            const { menuItemSelectedIcon, rawValues, fieldNames, virtual, listHeight, listItemHeight } = props;
            const renderOption = slots.option;
            const { activeIndex } = state;
            const omitFieldNameList = Object.keys(fieldNames).map(key => fieldNames[key]);
            // ========================== Render ==========================
            if (memoFlattenOptions.value.length === 0) {
                return (<div role="listbox" id={`${id}_list`} class={`${itemPrefixCls.value}-empty`} onMousedown={onListMouseDown}>
            {notFoundContent}
          </div>);
            }
            return (<>
          <div role="listbox" id={`${id}_list`} style={{ height: 0, width: 0, overflow: 'hidden' }}>
            {renderItem(activeIndex - 1)}
            {renderItem(activeIndex)}
            {renderItem(activeIndex + 1)}
          </div>
          <List itemKey="key" ref={listRef} data={memoFlattenOptions.value} height={listHeight} itemHeight={listItemHeight} fullHeight={false} onMousedown={onListMouseDown} onScroll={onPopupScroll} virtual={virtual} v-slots={{
                    default: (item, itemIndex) => {
                        const { group, groupOption, data, label, value } = item;
                        const { key } = data;
                        // Group
                        if (group) {
                            return (<div class={classNames(itemPrefixCls.value, `${itemPrefixCls.value}-group`)}>
                      {renderOption ? renderOption(data) : label !== undefined ? label : key}
                    </div>);
                        }
                        const { disabled, title, children, style, class: cls, className } = data, otherProps = __rest(data, ["disabled", "title", "children", "style", "class", "className"]);
                        const passedProps = omit(otherProps, omitFieldNameList);
                        // Option
                        const selected = rawValues.has(value);
                        const optionPrefixCls = `${itemPrefixCls.value}-option`;
                        const optionClassName = classNames(itemPrefixCls.value, optionPrefixCls, cls, className, {
                            [`${optionPrefixCls}-grouped`]: groupOption,
                            [`${optionPrefixCls}-active`]: activeIndex === itemIndex && !disabled,
                            [`${optionPrefixCls}-disabled`]: disabled,
                            [`${optionPrefixCls}-selected`]: selected,
                        });
                        const mergedLabel = getLabel(item);
                        const iconVisible = !menuItemSelectedIcon || typeof menuItemSelectedIcon === 'function' || selected;
                        const content = mergedLabel || value;
                        // https://github.com/ant-design/ant-design/issues/26717
                        let optionTitle = typeof content === 'string' || typeof content === 'number'
                            ? content.toString()
                            : undefined;
                        if (title !== undefined) {
                            optionTitle = title;
                        }
                        return (<div {...passedProps} aria-selected={selected} class={optionClassName} title={optionTitle} onMousemove={e => {
                                if (otherProps.onMousemove) {
                                    otherProps.onMousemove(e);
                                }
                                if (activeIndex === itemIndex || disabled) {
                                    return;
                                }
                                setActive(itemIndex);
                            }} onClick={e => {
                                if (!disabled) {
                                    onSelectValue(value);
                                }
                                if (otherProps.onClick) {
                                    otherProps.onClick(e);
                                }
                            }} style={style}>
                    <div class={`${optionPrefixCls}-content`}>
                      {renderOption ? renderOption(data) : content}
                    </div>
                    {isValidElement(menuItemSelectedIcon) || selected}
                    {iconVisible && (<TransBtn class={`${itemPrefixCls.value}-option-state`} customizeIcon={menuItemSelectedIcon} customizeIconProps={{ isSelected: selected }}>
                        {selected ? '✓' : null}
                      </TransBtn>)}
                  </div>);
                    },
                }}></List>
        </>);
        };
    },
});
export default OptionList;
