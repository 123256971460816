var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CalendarOutlined from '@ant-design/icons-vue/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons-vue/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons-vue/CloseCircleFilled';
import SwapRightOutlined from '@ant-design/icons-vue/SwapRightOutlined';
import { RangePicker as VCRangePicker } from '../../vc-picker';
import enUS from '../locale/en_US';
import { useLocaleReceiver } from '../../locale-provider/LocaleReceiver';
import { getRangePlaceholder } from '../util';
import { getTimeProps, Components } from '.';
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';
import useConfigInject from '../../_util/hooks/useConfigInject';
import classNames from '../../_util/classNames';
import { commonProps, rangePickerProps } from './props';
import devWarning from '../../vc-util/devWarning';
import { useInjectFormItemContext } from '../../form/FormItemContext';
export default function generateRangePicker(generateConfig, extraProps) {
    const RangePicker = defineComponent({
        name: 'ARangePicker',
        inheritAttrs: false,
        props: Object.assign(Object.assign(Object.assign({}, commonProps()), rangePickerProps()), extraProps),
        slots: [
            'suffixIcon',
            // 'clearIcon',
            // 'prevIcon',
            // 'nextIcon',
            // 'superPrevIcon',
            // 'superNextIcon',
            // 'panelRender',
            'dateRender',
            'renderExtraFooter',
            // 'separator',
        ],
        setup(_props, { expose, slots, attrs, emit }) {
            const props = _props;
            const formItemContext = useInjectFormItemContext();
            devWarning(!attrs.getCalendarContainer, 'DatePicker', '`getCalendarContainer` is deprecated. Please use `getPopupContainer"` instead.');
            const { prefixCls, direction, getPopupContainer, size, rootPrefixCls } = useConfigInject('picker', props);
            const pickerRef = ref();
            onMounted(() => {
                nextTick(() => {
                    var _a;
                    if (process.env.NODE_ENV === 'test') {
                        if (props.autofocus) {
                            (_a = pickerRef.value) === null || _a === void 0 ? void 0 : _a.focus();
                        }
                    }
                });
            });
            expose({
                focus: () => {
                    var _a;
                    (_a = pickerRef.value) === null || _a === void 0 ? void 0 : _a.focus();
                },
                blur: () => {
                    var _a;
                    (_a = pickerRef.value) === null || _a === void 0 ? void 0 : _a.blur();
                },
            });
            const maybeToStrings = (dates) => {
                return props.valueFormat ? generateConfig.toString(dates, props.valueFormat) : dates;
            };
            const onChange = (dates, dateStrings) => {
                const values = maybeToStrings(dates);
                emit('update:value', values);
                emit('change', values, dateStrings);
                formItemContext.onFieldChange();
            };
            const onOpenChange = (open) => {
                emit('update:open', open);
                emit('openChange', open);
            };
            const onFocus = () => {
                emit('focus');
            };
            const onBlur = () => {
                emit('blur');
                formItemContext.onFieldBlur();
            };
            const onPanelChange = (dates, modes) => {
                const values = maybeToStrings(dates);
                emit('panelChange', values, modes);
            };
            const onOk = (dates) => {
                const value = maybeToStrings(dates);
                emit('ok', value);
            };
            const onCalendarChange = (dates, dateStrings, info) => {
                const values = maybeToStrings(dates);
                emit('calendarChange', values, dateStrings, info);
            };
            const [contextLocale] = useLocaleReceiver('DatePicker', enUS);
            const value = computed(() => {
                if (props.value) {
                    return props.valueFormat
                        ? generateConfig.toDate(props.value, props.valueFormat)
                        : props.value;
                }
                return props.value;
            });
            const defaultValue = computed(() => {
                if (props.defaultValue) {
                    return props.valueFormat
                        ? generateConfig.toDate(props.defaultValue, props.valueFormat)
                        : props.defaultValue;
                }
                return props.defaultValue;
            });
            const defaultPickerValue = computed(() => {
                if (props.defaultPickerValue) {
                    return props.valueFormat
                        ? generateConfig.toDate(props.defaultPickerValue, props.valueFormat)
                        : props.defaultPickerValue;
                }
                return props.defaultPickerValue;
            });
            return () => {
                var _a, _b, _c;
                const locale = Object.assign(Object.assign({}, contextLocale.value), props.locale);
                const p = Object.assign(Object.assign({}, props), attrs);
                const { prefixCls: customizePrefixCls, bordered = true, placeholder, suffixIcon = (_a = slots.suffixIcon) === null || _a === void 0 ? void 0 : _a.call(slots), picker = 'date', transitionName, allowClear = true, dateRender = slots.dateRender, renderExtraFooter = slots.renderExtraFooter, separator = (_b = slots.separator) === null || _b === void 0 ? void 0 : _b.call(slots), clearIcon = (_c = slots.clearIcon) === null || _c === void 0 ? void 0 : _c.call(slots), id = formItemContext.id.value } = p, restProps = __rest(p, ["prefixCls", "bordered", "placeholder", "suffixIcon", "picker", "transitionName", "allowClear", "dateRender", "renderExtraFooter", "separator", "clearIcon", "id"]);
                delete restProps['onUpdate:value'];
                delete restProps['onUpdate:open'];
                const { format, showTime } = p;
                let additionalOverrideProps = {};
                additionalOverrideProps = Object.assign(Object.assign(Object.assign({}, additionalOverrideProps), (showTime ? getTimeProps(Object.assign({ format, picker }, showTime)) : {})), (picker === 'time' ? getTimeProps(Object.assign(Object.assign({ format }, restProps), { picker })) : {}));
                const pre = prefixCls.value;
                return (<VCRangePicker dateRender={dateRender} renderExtraFooter={renderExtraFooter} separator={separator || (<span aria-label="to" class={`${pre}-separator`}>
                  <SwapRightOutlined />
                </span>)} ref={pickerRef} placeholder={getRangePlaceholder(picker, locale, placeholder)} suffixIcon={suffixIcon || (picker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />)} clearIcon={clearIcon || <CloseCircleFilled />} allowClear={allowClear} transitionName={transitionName || `${rootPrefixCls.value}-slide-up`} {...restProps} {...additionalOverrideProps} id={id} value={value.value} defaultValue={defaultValue.value} defaultPickerValue={defaultPickerValue.value} picker={picker} class={classNames({
                        [`${pre}-${size.value}`]: size.value,
                        [`${pre}-borderless`]: !bordered,
                    }, attrs.class)} locale={locale.lang} prefixCls={pre} getPopupContainer={attrs.getCalendarContainer || getPopupContainer.value} generateConfig={generateConfig} prevIcon={<span class={`${pre}-prev-icon`}/>} nextIcon={<span class={`${pre}-next-icon`}/>} superPrevIcon={<span class={`${pre}-super-prev-icon`}/>} superNextIcon={<span class={`${pre}-super-next-icon`}/>} components={Components} direction={direction.value} onChange={onChange} onOpenChange={onOpenChange} onFocus={onFocus} onBlur={onBlur} onPanelChange={onPanelChange} onOk={onOk} onCalendarChange={onCalendarChange}/>);
            };
        },
    });
    return RangePicker;
}
